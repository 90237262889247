/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@media print {
  html,
  body {
    height: 250mm;
    width: 100%;
  }
  html .print-view,
  body .print-view {
    background: #fff;
    color: #000;
    zoom: 140%;
    page-break-inside: avoid;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
}

@-moz-document url-prefix() {
  @media only screen and (max-width: 768px) {
    body {
      padding-bottom: 50px;
    }
    .mozModalSpacer {
      height: 50px;
      display: block;
    }
  }
}

@layer components {
  .hide-clear-button::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 0;
    width: 0;
  }
}

img[role='presentation'] {
  outline: 0px;
}

#markdown-content *:last-child {
  @apply mb-0;
}

#markdown-content strong,
#markdown-content b {
  @apply font-ttHovesBold;
}

#markdown-content i,
#markdown-content em {
  @apply font-ttHovesItalic;
}

#markdown-content u {
  @apply underline;
}

#markdown-content a,
#markdown-content a:link,
#markdown-content a:active,
#markdown-content a:visited {
  @apply text-blue;
  @apply underline;
}

#markdown-content a:hover,
#markdown-content a:link:hover,
#markdown-content a:active:hover,
#markdown-content a:visited:hover {
  @apply no-underline;
}

#markdown-content h1 {
  @apply text-2xl;
  @apply font-ttHovesBold;
}

#markdown-content h2 {
  @apply text-xl;
  @apply font-ttHovesBold;
}

#markdown-content h3 {
  @apply text-lg;
  @apply font-ttHovesBold;
}

#markdown-content h4 {
  @apply text-base;
  @apply font-ttHovesBold;
}

#markdown-content h5 {
  @apply text-sm;
  @apply font-ttHovesBold;
}

#markdown-content p,
#markdown-content ul,
#markdown-content ol {
  @apply mb-5;
}

#markdown-content ol,
#markdown-content ul {
  @apply ml-5;
}

#markdown-content ol {
  @apply list-decimal;
}

#markdown-content ul {
  @apply list-disc;
}

.mapboxgl-popup {
  z-index: 10;
}

.terminal,
.mapboxgl-popup-content {
  @apply bg-white;
  @apply shadow-xl;
  @apply px-4;
  @apply pt-6;
  @apply pb-6;
  @apply text-nptAGreen;
}

.terminal img,
.mapboxgl-popup-content img {
  @apply w-10;
  @apply mb-6;
}

.terminal h1,
.mapboxgl-popup-content h1 {
  @apply text-xl;
  @apply mb-4;
  @apply font-ttHovesMedium;
}

.terminal p,
.mapboxgl-popup-content p {
  @apply text-base;
  @apply mb-2;
  @apply font-ttHovesRegular;
}

.terminal.terminal,
.mapboxgl-popup-content.terminal {
  @apply shadow-none;
}

.mapboxgl-popup-tip {
  display: none;
}

.__perk a {
  @apply underline decoration-[#3B6A62];
}
/*# sourceMappingURL=globals.css.map */

.shipments-table {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 3px !important;
  border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
}

.details-table {
  border-collapse: separate;
  border-spacing: 0;
}

.box-shadow-l {
  box-shadow: 1px 0 0 0 #e0e0e0;
}

.invite-checkbox:checked {
  @apply bg-green-portal;
}

.invite-checkbox:focus {
  @apply ring-2 ring-green-portal;
}

.invite-checkbox:active {
  @apply bg-green-portal text-green-portal;
}

[type='checkbox'] {
  @apply text-green-portal;
}

.ShipmentDetailCard,
.ShipmentDetailCardWide,
.ShipmentHistoryCard {
  @apply h-full;
}

.react-pdf__Document {
  border: 1px solid #bdbdbd;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.search-input::placeholder {
  @apply text-sm italic text-gray-filter;
}

.search-input::-webkit-outer-spin-button,
.search-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.search-input[type='number'] {
  -moz-appearance: textfield;
}

/* Cta(s) */
.__Npt-Cta {
  @apply rounded-[4px] focus-visible:outline-none cursor-pointer;

  & > * {
    @apply cursor-pointer;
  }
  &-link {
    @apply transition-all duration-300 border border-transparent hover:underline underline-offset-2;
    @apply active:ring-offset-2 active:ring-green-success active:ring-2;
    @apply focus-visible:ring-2 focus-visible:ring-blue;
    @apply group-active:ring-offset-2 group-active:ring-green-success group-active:ring-2;
    @apply group-focus-visible:ring-2 group-focus-visible:ring-blue;
  }
  &-link-disabled {
    @apply cursor-not-allowed border-gray-border text-gray-filter;
    & > * {
      @apply cursor-not-allowed;
    }
  }
  &-dark {
    @apply text-white bg-green-100 border rounded-md border-green-success;
    @apply border-green-100 hover:bg-green-success;
    @apply active:ring-offset-2 active:ring-green-success active:ring-2;
    @apply focus-visible:ring-blue focus-visible:ring-offset-2 focus-visible:ring-offset-white focus-visible:ring-2;
  }
  &-dark-disabled {
    @apply cursor-not-allowed disabled:bg-gray-border disabled:text-black-text;
    & > * {
      @apply cursor-not-allowed;
    }
  }
  &-light {
    @apply bg-white text-black border border-gray-border min-w-[178px];
    @apply hover:bg-green-light;
    @apply active:ring-offset-2 active:ring-green-success active:ring-2;
    @apply focus-visible:ring-2 focus-visible:ring-blue;
    @apply group-active:ring-offset-2 group-active:ring-green-success group-active:ring-2;
    @apply group-focus-visible:ring-2 group-focus-visible:ring-blue;
  }
  &-light-disabled {
    @apply cursor-not-allowed disabled:border disabled:border-gray-border disabled:opacity-40;
    & > * {
      @apply cursor-not-allowed;
    }
  }
}

.table-head-regular {
  font-weight: 500 !important;
}

.api-bullet a,
.api-bullet a:link,
.api-bullet a:active,
.api-bullet a:visited {
  @apply text-blue-link;
}

.terminal,
.mapboxgl-popup-content {
  @apply bg-white;
  @apply shadow-xl;
  @apply px-4;
  @apply pt-6;
  @apply pb-6;
  @apply text-green-100;
}

.__perk {
  p {
    @apply text-xl lg:text-[2.125rem] font-ttHovesRegular leading-[120%] tracking-[.01em];
  }
  a {
    @apply underline underline-offset-2  decoration-[#3B6A62];
  }
}

.__employeeOwnedTitle {
  p {
    @apply font-ttHovesBold text-[1.25rem] leading-[1.5rem] xl:text-[1.5rem]  font-bold text-green-200 text-left w-full mb-[24px] lg:mb-[20px];
  }
}

.__employeeOwnedSubtitle {
  p {
    @apply font-ttHovesMedium w-[99.9%] text-[1.25rem] leading-[1.5rem] md:text-lg xl:text-[1.5rem] xl:leading-[1.75rem]  text-left text-green-100 lg:w-full lg:block flex flex-col gap-y-[24px];
  }

  .custom-track {
    @apply -tracking-[.015rem] xl:-tracking-[.045rem];
  }

  p:nth-of-type(even) {
    @apply mt-4 lg:mt-6 xl:mt-8 lg:mb-0;
  }
}

.__comparisonEyebrow {
  @apply mb-5 font-ttHovesMedium text-nptBase text-gray-0 lg:mb-3 empty:mb-0;

  a {
    @apply underline decoration-[#3B6A62];
  }
}

.__comparisonTitle {
  p {
    @apply font-ttHovesMedium text-[1.25rem] pl-1 w-[99%] lg:pl-0 lg:w-full leading-[1.5rem]  text-green-100 mb-[37px];
  }
}

.__comparisonCopy {
  @apply basis-16 grow;
  p {
    @apply w-full inline font-ttHovesMedium text-nptBase md:whitespace-nowrap text-green-100 md:w-[95%];
  }
}

.slanted {
  @apply relative flex flex-col items-center justify-center w-full;
}
.slanted::after {
  @apply -bottom-[40%] md:-bottom-[60%] lg:-bottom-[260px] skew-y-[12deg];
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  transform-origin: left bottom;
}
/*# sourceMappingURL=globals.css.map */
.hero-highlight {
  @apply text-nptCRed;
}
[data-aos] {
  visibility: hidden;
}
[data-aos].aos-animate {
  visibility: visible;
}

[data-aos='trans-npk'] {
  will-change: transform;
  opacity: 1;
  visibility: visible;
  &.aos-animate {
    animation: westcoast-translate 1s ease-in-out forwards;
  }
}
[data-aos='trans-east'] {
  will-change: transform;
  opacity: 1;
  visibility: visible;
  &.aos-animate {
    animation: eastcoast-translate 1s ease-in-out forwards;
  }
}
[data-aos='scroll-npk'] {
  will-change: transform;
  opacity: 0;
  &.aos-animate {
    opacity: 1;
    animation: marquee 7s ease-in-out infinite alternate;
  }
}
[data-aos='scroll-service'] {
  will-change: color;
  opacity: 0;
  &.aos-animate {
    opacity: 1;
    animation: service 7s ease-in-out infinite alternate;
  }
}
[data-aos='scroll-quotes'] {
  will-change: color;
  opacity: 0;
  &.aos-animate {
    opacity: 1;
    animation: quote 7s ease-in-out infinite alternate;
  }
}
[data-aos='scroll-tracking'] {
  will-change: color;
  opacity: 0;
  &.aos-animate {
    opacity: 1;
    animation: tracking 7s ease-in-out infinite alternate;
  }
}
[data-aos='scroll-pickups'] {
  will-change: color;
  opacity: 0;
  &.aos-animate {
    opacity: 1;
    animation: pickup 7s ease-in-out infinite alternate;
  }
}
[data-aos='scroll-logistics'] {
  will-change: color;
  opacity: 0;
  &.aos-animate {
    opacity: 1;
    animation: logistic 7s ease-in-out infinite alternate;
  }
}
[data-aos='mobile-track'] {
  will-change: transform;
  opacity: 0;
  &.aos-animate {
    opacity: 1;
    animation: mobile-marquee 7s ease-in-out infinite alternate;
  }
}

@keyframes westcoast-translate {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(1vw);
  }
}
@keyframes eastcoast-translate {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1vw);
  }
}
@keyframes marquee {
  0%,
  11% {
    transform: translateY(0);
  }
  22%,
  33% {
    transform: translateY(-66px);
  }
  44%,
  55% {
    transform: translateY(-132px);
  }
  66%,
  77% {
    transform: translateY(-198px);
  }
  88%,
  100% {
    transform: translateY(-264px);
  }
}
@keyframes mobile-marquee {
  0%,
  11% {
    transform: translateY(0);
  }
  22%,
  33% {
    transform: translateY(-58px);
  }
  44%,
  55% {
    transform: translateY(-116px);
  }
  66%,
  77% {
    transform: translateY(-174px);
  }
  88%,
  100% {
    transform: translateY(-232px);
  }
}

@keyframes service {
  0%,
  11% {
    color: #cc3b33;
  }
  22%,
  33% {
    color: #00000033;
  }
  44%,
  55% {
    color: #0000001a;
  }
  66%,
  77% {
    color: #0000000d;
  }
  88%,
  100% {
    color: #00000000;
  }
}
@keyframes quote {
  0%,
  11% {
    color: #00000033;
  }
  22%,
  33% {
    color: #cc3b33;
  }
  44%,
  55% {
    color: #00000033;
  }
  66%,
  77% {
    color: #0000001a;
  }
  88%,
  100% {
    color: #0000000d;
  }
}
@keyframes tracking {
  0%,
  11% {
    color: #0000001a;
  }
  22%,
  33% {
    color: #00000033;
  }
  44%,
  55% {
    color: #cc3b33;
  }
  66%,
  77% {
    color: #00000033;
  }
  88%,
  100% {
    color: #0000001a;
  }
}
@keyframes pickup {
  0%,
  11% {
    color: #0000000d;
  }
  22%,
  33% {
    color: #0000001a;
  }
  44%,
  55% {
    color: #00000033;
  }
  66%,
  77% {
    color: #cc3b33;
  }
  88%,
  100% {
    color: #00000033;
  }
}
@keyframes logistic {
  0%,
  11% {
    color: #00000003;
  }
  22%,
  33% {
    color: #0000000d;
  }
  44%,
  55% {
    color: #0000001a;
  }
  66%,
  77% {
    color: #00000033;
  }
  88%,
  100% {
    color: #cc3b33;
  }
}
